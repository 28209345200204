<template>
  <b-card>
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.author_id.label')"
                label-for="author_id"
                :state="errors && errors.user_id ? false : null"
              >
                <v-select
                  id="author_id"
                  v-model="item.user_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="author_id"
                  autocomplete="off"
                  :searchable="true"
                  @search="searchUser"
                />
                <b-form-invalid-feedback v-if="errors && errors.user_id">
                  {{ errors.user_id[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.status.label')"
                label-for="status"
                :state="errors && errors.status ? false : null"
              >
                <v-select
                  id="status"
                  v-model="item.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptionsTransactions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
                <b-form-invalid-feedback v-if="errors && errors.status">
                  {{ errors.status[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('market.table.fields.rating')"
                label-for="rating"
                :state="errors && errors.rating ? false : null"
              >
                <v-select
                  id="rating"
                  v-model="item.rating"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[1,2,3,4,5]"
                  :clearable="false"
                  input-id="rating"
                />
                <b-form-invalid-feedback v-if="errors && errors.rating">
                  {{ errors.rating[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <b-form-group
                :label="$t('market.table.fields.reviewable')"
                label-for="reviewable_id"
              >
                <v-select
                  id="reviewable_id"
                  v-model="item.reviewable_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="channelOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="reviewable_id"
                  autocomplete="off"
                  :searchable="true"
                  @search="searchChannel"
                />
                <b-form-invalid-feedback v-if="errors && errors.reviewable_id">
                  {{ errors.reviewable_id[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('form.description.label')"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="item.description"
                  rows="8"
                  :state="errors && errors.description ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.description">
                  {{ errors.description[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:posts-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: {
        status: 'pending',
        description: null,
        user_id: null,
        rating: 5,
        parent: null,
        reply_id: null,
        reviewable_id: null,
        reviewable_type: 'Product',
      },
      userOptions: [],
      channelOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  // async beforeCreate() {
  //   this.item = this.transformData()
  // },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        status: this.item.status,
        description: this.item.description,
        user_id: this.item.user_id,
        rating: this.item.rating,
        parent: this.item.parent,
        reply_id: this.item.reply_id,
        reviewable_id: this.item.reviewable_id,
        reviewable_type: this.item.reviewable_type,
      }

      this.$http.post('/api/admin/reviews', data)
        .then(() => {
          router.replace({ name: 'admin:reviews-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      return {
        status: 'pending',
        description: null,
        user_id: null,
        rating: 5,
        parent: null,
        reply_id: null,
        reviewable_id: null,
        reviewable_type: 'Product',
      }
    },
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
    async searchChannel(query) {
      await this.$http.get('/api/admin/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.channelOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
